<template>
  <b-col class="col-12 col-md-8 col-lg-6 pt-5 px-3 p_b__3">
    <h4 class="mb-4 font-weight-normal text-muted">
      {{ $t("generalSettings") }}
    </h4>
    <div>
      <!-- ACCOUNT DETAILS -->
      <b-card no-body class="px-3 py-3 mb-3">
        <validation-observer ref="accountForm" v-slot="{ valid }">
          <b-form>
            <b-row class="no-gutters align-items-center">
              <b-col>
                <h6 class="mb-0 font-weight-normal text-uppercase">
                  {{ $t("account") }}
                </h6>
              </b-col>
              <b-col class="col-auto">
                <b-button
                  v-if="editAccountStatus"
                  size="sm"
                  class="text-uppercase mr-1"
                  variant="outline-gray-dark"
                  @click="cancelEditAccountForm"
                >
                  <span>{{ $t("button.cancel") }}</span>
                </b-button>
                <b-button
                  v-if="editAccountStatus"
                  size="sm"
                  class="text-uppercase"
                  variant="primary"
                  @click="submitAccount"
                  :disabled="!valid"
                >
                  <span>{{ $t("button.save") }}</span>
                </b-button>
                <b-button
                  v-else
                  size="sm"
                  class="text-uppercase"
                  variant="outline-gray-dark"
                  @click="activateEditAccountForm"
                >
                  <img
                    src="./../assets/general-icons/pencil-alt-2.svg"
                    class="action__icon clickable mr-1"
                  />
                  <span>{{ $t("button.edit") }}</span>
                </b-button>
              </b-col>
            </b-row>

            <hr class="my-3 mx-n3 divider" />
            <b-row class="no-gutters">
              <b-col class="col-12 col-sm-6 col-lg-4 pr-3 mb-3">
                <b-form-group
                  label-class="font-weight-bold"
                  :label="$t('firstName')"
                  label-for="firstName"
                >
                  <validation-provider
                    v-if="editAccountStatus"
                    immediate
                    :name="$t('firstName')"
                    :rules="{ required: true }"
                    v-slot="validationContext"
                  >
                    <b-form-input
                      id="firstName"
                      class="input__field"
                      v-model="clientAccountInfo.firstName"
                      :state="getValidationState(validationContext)"
                    ></b-form-input>
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                  <div v-else class="text-muted mt-n2">
                    {{ clientAccountInfo.firstName }}
                  </div>
                </b-form-group>
              </b-col>
              <b-col class="col-12 col-sm-6 col-lg-4 pr-3 mb-3">
                <b-form-group
                  label-class="font-weight-bold"
                  :label="$t('lastName')"
                  label-for="lastName"
                >
                  <validation-provider
                    v-if="editAccountStatus"
                    :name="$t('lastName')"
                    :rules="{ required: true }"
                    v-slot="validationContext"
                  >
                    <b-form-input
                      id="lastName"
                      class="input__field"
                      v-model="clientAccountInfo.lastName"
                      :state="getValidationState(validationContext)"
                    ></b-form-input>
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                  <div v-else class="text-muted mt-n2">
                    {{ clientAccountInfo.lastName }}
                  </div>
                </b-form-group>
              </b-col>
              <b-col class="col-12 col-sm-6 col-lg-4 pr-3 mb-3">
                <b-form-group
                  label-class="font-weight-bold"
                  :label="$t('email')"
                  label-for="email"
                >
                  <!-- <validation-provider
                    v-if="editAccountStatus"
                    :name="$t('email')"
                    :rules="{ required: true, email: true }"
                    v-slot="validationContext"
                  >
                    <b-form-input
                      id="email"
                      class="input__field"
                      v-model="clientAccountInfo.email"
                      :state="getValidationState(validationContext)"
                    ></b-form-input>
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider> -->
                  <div class="text-muted mt-n2">
                    {{ clientAccountInfo.email }}
                  </div>
                </b-form-group>
              </b-col>
              <b-col class="col-12 col-sm-6 col-lg-4 pr-3 mb-3">
                <b-form-group
                  label-class="font-weight-bold"
                  :label="$t('jobTitle')"
                  label-for="jobTitle"
                >
                  <validation-provider
                    v-if="editAccountStatus"
                    :name="$t('jobTitle')"
                    :rules="{ required: true }"
                    v-slot="validationContext"
                  >
                    <b-form-input
                      id="jobTitle"
                      class="input__field"
                      v-model="clientAccountInfo.jobTitle"
                      :state="getValidationState(validationContext)"
                    ></b-form-input>
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                  <div v-else class="text-muted mt-n2">
                    {{ clientAccountInfo.jobTitle }}
                  </div>
                </b-form-group>
              </b-col>
              <b-col class="col-12 col-sm-6 col-lg-4 pr-3 mb-3">
                <b-form-group
                  label-class="font-weight-bold"
                  :label="$t('phone')"
                  label-for="phone"
                >
                  <validation-provider
                    v-if="editAccountStatus"
                    :name="$t('phone')"
                    :rules="{ required: true }"
                    v-slot="validationContext"
                  >
                    <b-form-input
                      id="phone"
                      class="input__field"
                      v-model="clientAccountInfo.phone"
                      :state="getValidationState(validationContext)"
                    ></b-form-input>
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                  <div v-else class="text-muted mt-n2">
                    {{ clientAccountInfo.phone }}
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-card>
      <!-- BILLING DETAILS -->
      <b-card no-body class="px-3 py-3 mb-3">
        <validation-observer ref="accountForm" v-slot="{ valid }">
          <b-form>
            <b-row class="no-gutters align-items-center">
              <b-col>
                <h6 class="mb-0 font-weight-normal text-uppercase">
                  {{ $t("billingDetails") }}
                </h6>
              </b-col>
              <b-col class="col-auto">
                <b-button
                  v-if="editBillingStatus"
                  size="sm"
                  class="text-uppercase mr-1"
                  variant="outline-gray-dark"
                  @click="cancelEditBillingForm"
                >
                  <span>{{ $t("button.cancel") }}</span>
                </b-button>
                <b-button
                  v-if="editBillingStatus"
                  size="sm"
                  class="text-uppercase"
                  variant="primary"
                  @click="submitBillingInfo"
                  :disabled="!valid"
                >
                  <span>{{ $t("button.save") }}</span>
                </b-button>
                <b-button
                  v-else
                  size="sm"
                  class="text-uppercase"
                  variant="outline-gray-dark"
                  @click="activateEditBillingForm"
                >
                  <img
                    src="./../assets/general-icons/pencil-alt-2.svg"
                    class="action__icon clickable mr-1"
                  />
                  <span>{{ $t("button.edit") }}</span>
                </b-button>
              </b-col>
            </b-row>

            <hr class="my-3 mx-n3 divider" />
            <b-row class="no-gutters">
              <b-col class="col-12 col-sm-6 col-lg-4 pr-3 mb-3">
                <b-form-group
                  label-class="font-weight-bold"
                  :label="$t('company')"
                  label-for="company"
                >
                  <validation-provider
                    v-if="editBillingStatus"
                    immediate
                    :name="$t('company')"
                    :rules="{ required: true }"
                    v-slot="validationContext"
                  >
                    <b-form-input
                      id="company"
                      class="input__field"
                      v-model="clientBillingInfo.companyName"
                      :state="getValidationState(validationContext)"
                    ></b-form-input>
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                  <div v-else class="text-muted mt-n2">
                    {{ clientBillingInfo.companyName }}
                  </div>
                </b-form-group>
              </b-col>
              <b-col class="col-12 col-sm-6 col-lg-4 pr-3 mb-3">
                <b-form-group
                  label-class="font-weight-bold"
                  :label="$t('country')"
                  label-for="country"
                >
                  <validation-provider
                    v-if="editBillingStatus"
                    immediate
                    :name="$t('country')"
                    :rules="{ required: true }"
                    v-slot="validationContext"
                  >
                    <b-form-select
                      id="country"
                      v-model="clientBillingInfo.country"
                      :options="countryList"
                      :state="getValidationState(validationContext)"
                      value-field="name"
                      text-field="name"
                    >
                      <template v-slot:first>
                        <b-form-select-option :value="null" disabled>
                          {{ $t("selectAnOption") }}
                        </b-form-select-option>
                      </template>
                    </b-form-select>
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                  <div v-else class="text-muted mt-n2">
                    {{ clientBillingInfo.country }}
                  </div>
                </b-form-group>
              </b-col>
              <b-col class="col-12 col-sm-6 col-lg-4 pr-3 mb-3">
                <b-form-group
                  label-class="font-weight-bold"
                  :label="$t('city')"
                  label-for="city"
                >
                  <validation-provider
                    v-if="editBillingStatus"
                    immediate
                    :name="$t('city')"
                    :rules="{ required: true }"
                    v-slot="validationContext"
                  >
                    <b-form-input
                      id="city"
                      class="input__field"
                      v-model="clientBillingInfo.city"
                      :state="getValidationState(validationContext)"
                    ></b-form-input>
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                  <div v-else class="text-muted mt-n2">
                    {{ clientBillingInfo.city }}
                  </div>
                </b-form-group>
              </b-col>
              <b-col class="col-12 col-sm-6 col-lg-4 pr-3 mb-3">
                <b-form-group
                  label-class="font-weight-bold"
                  :label="$t('address')"
                  label-for="address"
                >
                  <validation-provider
                    v-if="editBillingStatus"
                    immediate
                    :name="$t('address')"
                    :rules="{ required: true }"
                    v-slot="validationContext"
                  >
                    <b-form-input
                      id="address"
                      class="input__field"
                      v-model="clientBillingInfo.address"
                      :state="getValidationState(validationContext)"
                    ></b-form-input>
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                  <div v-else class="text-muted mt-n2">
                    {{ clientBillingInfo.address }}
                  </div>
                </b-form-group>
              </b-col>
              <b-col class="col-12 col-sm-6 col-lg-4 pr-3 mb-3">
                <b-form-group
                  label-class="font-weight-bold"
                  :label="$t('province')"
                  label-for="province"
                >
                  <validation-provider
                    v-if="editBillingStatus"
                    immediate
                    :name="$t('province')"
                    :rules="{ required: true, length: 2 }"
                    v-slot="validationContext"
                  >
                    <b-form-input
                      id="province"
                      class="input__field"
                      v-model="clientBillingInfo.province"
                      :state="getValidationState(validationContext)"
                    ></b-form-input>
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                  <div v-else class="text-muted mt-n2">
                    {{ clientBillingInfo.province }}
                  </div>
                </b-form-group>
              </b-col>
              <b-col class="col-12 col-sm-6 col-lg-4 pr-3 mb-3">
                <b-form-group
                  label-class="font-weight-bold"
                  :label="$t('VATNumber')"
                  label-for="VATNumber"
                >
                  <validation-provider
                    v-if="editBillingStatus"
                    immediate
                    :name="$t('VATNumber')"
                    :rules="{ required: true }"
                    v-slot="validationContext"
                  >
                    <b-form-input
                      id="VATNumber"
                      class="input__field"
                      v-model="clientBillingInfo.vat"
                      :state="getValidationState(validationContext)"
                    ></b-form-input>
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                  <div v-else class="text-muted mt-n2">
                    {{ clientBillingInfo.vat }}
                  </div>
                </b-form-group>
              </b-col>
              <b-col class="col-12 col-sm-6 col-lg-4 pr-3 mb-3">
                <b-form-group
                  label-class="font-weight-bold"
                  :label="$t('sdlCode')"
                  label-for="sdlCode"
                >
                  <validation-provider
                    v-if="editBillingStatus"
                    immediate
                    :name="$t('sdlCode')"
                    :rules="{ required: true }"
                    v-slot="validationContext"
                  >
                    <b-form-input
                      id="sdlCode"
                      class="input__field"
                      v-model="clientBillingInfo.sdlCode"
                      :state="getValidationState(validationContext)"
                    ></b-form-input>
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                  <div v-else class="text-muted mt-n2">
                    {{ clientBillingInfo.sdlCode }}
                  </div>
                </b-form-group>
              </b-col>
              <b-col class="col-12 col-sm-6 col-lg-4 pr-3 mb-3">
                <b-form-group
                  label-class="font-weight-bold"
                  :label="$t('PEC')"
                  label-for="PEC"
                >
                  <validation-provider
                    v-if="editBillingStatus"
                    immediate
                    :name="$t('PEC')"
                    :rules="{ required: true }"
                    v-slot="validationContext"
                  >
                    <b-form-input
                      id="PEC"
                      class="input__field"
                      v-model="clientBillingInfo.pec"
                      :state="getValidationState(validationContext)"
                    ></b-form-input>
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                  <div v-else class="text-muted mt-n2">
                    {{ clientBillingInfo.pec }}
                  </div>
                </b-form-group>
              </b-col>
              <b-col class="col-12 col-sm-6 col-lg-4 pr-3 mb-3">
                <b-form-group
                  label-class="font-weight-bold"
                  :label="$t('zip')"
                  label-for="zip"
                >
                  <validation-provider
                    v-if="editBillingStatus"
                    immediate
                    :name="$t('zip')"
                    :rules="{ required: true }"
                    v-slot="validationContext"
                  >
                    <b-form-input
                      id="zip"
                      class="input__field"
                      v-model="clientBillingInfo.zip"
                      :state="getValidationState(validationContext)"
                    ></b-form-input>
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                  <div v-else class="text-muted mt-n2">
                    {{ clientBillingInfo.zip }}
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-card>

      <!-- CHANGE PASSWORD -->
      <!-- <b-card no-body class="px-3 py-3 mb-3">
        <validation-observer ref="accountForm" v-slot="{ valid }">
          <b-form>
            <h6 class="mb-0 font-weight-normal text-uppercase">
              {{ $t("password") }}
            </h6>
            <hr class="my-3 mx-n3 divider" />
            <b-form-group
              label-class="font-weight-bold"
              :label="$t('currentPassword')"
              label-for="currentPassword"
            >
              <validation-provider
                :name="$t('currentPassword')"
                :rules="{ required: true }"
                v-slot="validationContext"
              >
                <b-form-input
                  v-model="currentPassword"
                  type="password"
                  :placeholder="$t('currentPassword')"
                  :state="getValidationState(validationContext)"
                ></b-form-input>
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label-class="font-weight-bold"
              :label="$t('newPassword')"
            >
              <p class="small text-muted">{{ $t("passwordRulesText") }}</p>
              <validation-provider
                name="newPassword"
                :rules="{ required: true, password: true }"
                v-slot="validationContext"
              >
                <b-form-input
                  v-model="newPassword"
                  type="password"
                  :placeholder="$t('newPassword')"
                  :state="getValidationState(validationContext)"
                ></b-form-input>
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
              <validation-provider
                :name="$t('passwordConfirmation')"
                rules="required|passwordMatch:@newPassword"
                v-slot="validationContext"
              >
                <b-form-input
                  v-model="passwordConfirmation"
                  class="mt-3"
                  type="password"
                  :placeholder="$t('passwordConfirmation')"
                  :state="getValidationState(validationContext)"
                ></b-form-input>
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
            <b-row class="no-gutters">
              <b-button
                class="ml-auto secondary__btn"
                variant="secondary"
                :disabled="!valid"
                @click="changePassword"
              >
                {{ $t("button.updatePassword") }}
              </b-button>
            </b-row>
          </b-form>
        </validation-observer>
      </b-card> -->

      <!-- NOTIFICATIONS -->
      <b-card no-body class="px-3 py-3 mb-3">
        <h6 class="mb-0 font-weight-normal text-uppercase">
          {{ $t("notificationSettings") }}
        </h6>
        <hr class="my-3 mx-n3 divider" />
        <b-form-checkbox v-model="newsletter" switch>
          <span class="text-muted ml-3">{{ $t("newsletterText") }}</span>
        </b-form-checkbox>
        <p class="mt-4 text__xs">
          <span>{{ $t("policy.text") }}</span>
          <b-link
            target="_blank"
            :href="getExternalLink(privacyLinks)"
            class="link text-red"
          >
            {{ $t("policy.link") }}
          </b-link>
        </p>
      </b-card>
    </div>
  </b-col>
</template>

<script>
import Utils from "./../Utils";
export default {
  watch: {
    newsletter: {
      handler: function() {
        if (this.dataLoaded) this.changeNewsletterPreference();
      },
      immediate: false
    }
  },
  data() {
    return {
      dataLoaded: false,
      countryList: Utils.COUNTRY_LIST,
      editAccountStatus: false,
      editBillingStatus: false,
      clientAccountInfo: {},
      clientBillingInfo: {},
      currentPassword: "",
      newPassword: "",
      passwordConfirmation: "",
      newsletter: false,
      privacyLinks: {
        hrefEn: "https://emotiva.it/en/empower-privacy-policy",
        hrefIt: "https://emotiva.it/empower-privacy-policy-2/"
      }
    };
  },
  created() {
    this.initAccountInfo();
    this.initBillingInfo();
    this.initNewsletter();
  },
  computed: {
    userAccount() {
      return { ...this.$store.getters["auth/getUserAccount"] };
    }
  },
  methods: {
    getExternalLink(linkItem) {
      return Utils.getExternalLinkByLang({
        linkEn: linkItem.hrefEn,
        linkIt: linkItem.hrefIt
      });
    },
    initAccountInfo() {
      this.clientAccountInfo = {
        firstName: this.userAccount.firstName,
        lastName: this.userAccount.lastName,
        email: this.userAccount.registration?.email
          ? this.userAccount.registration.email
          : "-",
        jobTitle: this.userAccount.jobTitle,
        phone: this.userAccount.phone,
      };
      this.editAccountStatus = false;
    },
    initBillingInfo() {
      this.clientBillingInfo = {
        companyName: this.userAccount.companyName,
        country: this.userAccount.country,
        city: this.userAccount.city,
        address: this.userAccount.address,
        province: this.userAccount.province,
        vat: this.userAccount.vat,
        sdlCode: this.userAccount.sdlCode,
        pec: this.userAccount.pec,
        zip: this.userAccount.zip
      };
      this.editBillingStatus = false;
    },
    initNewsletter() {
      // Use 'dataLoaded' in order to prevent send request on init (in watch)
      this.$nextTick(() => {
        this.dataLoaded = true;
      });
      this.newsletter = this.userAccount.newsletterRegistration;
    },
    getValidationState({ dirty, validated, valid = null, untouched }) {
      // if (!untouched && (dirty || validated)) return valid ? null : valid;
      // else return null;
      if (!untouched) return valid ? null : valid;
      else return null;
    },
    activateEditAccountForm() {
      this.editAccountStatus = true;
    },
    cancelEditAccountForm() {
      this.initAccountInfo();
    },
    submitAccount() {
      const currentUserAccount = this.userAccount;
      currentUserAccount.firstName = this.clientAccountInfo.firstName;
      currentUserAccount.lastName = this.clientAccountInfo.lastName;
      // Is this actually needed if email is not mutable after initial user creation? Disabling for now
      // currentUserAccount.registration.email = this.clientAccountInfo.email;
      currentUserAccount.jobTitle = this.clientAccountInfo.jobTitle;
      currentUserAccount.phone = this.clientAccountInfo.phone;
      this.updateUserAccount(currentUserAccount, this.initAccountInfo);
    },
    activateEditBillingForm() {
      this.editBillingStatus = true;
    },
    cancelEditBillingForm() {
      this.initBillingInfo();
    },
    submitBillingInfo() {
      const currentUserAccount = this.userAccount;
      currentUserAccount.companyName = this.clientBillingInfo.companyName;
      currentUserAccount.country = this.clientBillingInfo.country;
      currentUserAccount.city = this.clientBillingInfo.city;
      currentUserAccount.address = this.clientBillingInfo.address;
      currentUserAccount.province = this.clientBillingInfo.province;
      currentUserAccount.vat = this.clientBillingInfo.vat;
      currentUserAccount.sdlCode = this.clientBillingInfo.sdlCode;
      currentUserAccount.pec = this.clientBillingInfo.pec;
      currentUserAccount.zip = this.clientBillingInfo.zip;
      this.updateUserAccount(currentUserAccount, this.initBillingInfo);
    },
    changePassword() {
      this.$store.commit("loader/SET_LOADER", {});
      this.$store
        .dispatch("auth/changeUserPassword", {
          oldPassword: this.currentPassword,
          newPassword: this.newPassword
        })
        .catch(() => {})
        .finally(() => this.$store.dispatch("loader/close"));
    },
    changeNewsletterPreference() {
      const currentUserAccount = this.userAccount;
      currentUserAccount.newsletterRegistration = this.newsletter;
      this.updateUserAccount(currentUserAccount);
    },
    updateUserAccount(userAccount, resetClb = null) {
      this.$store.commit("loader/SET_LOADER", {});
      this.$store.commit("auth/SET_USER_ACCOUNT", userAccount);
      this.$store
        .dispatch("auth/updateClient")
        .then(() => {
          if (resetClb) resetClb();
        })
        .catch(() => {})
        .finally(() => {
          this.$store.dispatch("loader/close");
        });
    }
  }
};
</script>

<style scoped>
.divider {
  border-top: 1px black solid;
}
.action__icon {
  width: 14px;
  filter: invert(49%) sepia(7%) saturate(539%) hue-rotate(167deg)
    brightness(90%) contrast(88%);
}
.input__field.form-control:disabled {
  border: unset;
  background-color: initial;
  padding: 0;
  color: #6c757d;
  margin-top: -16px;
}
.secondary__btn.btn-secondary.disabled,
.secondary__btn.btn-secondary:disabled {
  color: black;
}
</style>
